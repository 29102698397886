<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="发送对象" prop="user_id">
        {{ form.user_id }}
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="短信内容" prop="content">
        <el-input
            type="textarea"
            :autosize="{ minRows: 5}"
            placeholder="请输入内容"
            v-model="form.content"
            maxlength="500"
            show-word-limit
            @input="descInput">
        </el-input>
        <div>发送时系统会自动在结尾追加[优品汇]，请勿重复添加。</div>
        <div>
          内容上限不能超过<span class="color">500</span>字，
          当前已输入<span class="color">{{ numbers }}</span>字，将作为<span class="color">1</span>条发送
        </div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="发送统计">
        <div>
          发送条数：<span class="color">20</span>条
          <span style="margin-left: 20px">
            可用短信条数：<span class="color">1000</span>条
          </span>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      coustate: true,
      numbers:0,
      form: {
        content: '',
      },
      rules: {
        content: [
          { required: true, trigger: 'blur', message: '请输入短信内容' },
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    // 获取输入数量
    descInput(){
      var txtVal = this.form.content.length;
      this.numbers = txtVal
    },
    handlePic(res) {
      console.log(res)
      const { code, data } = res
      if (code == 200) {
        this.form.pic = data
      }
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '群发短信'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      console.log(event)
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const { code, message } = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.color {
  color: #ff4d51;
  font-weight: 700;
}
</style>
