<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="推送类型" prop="kind">
        <el-radio-group v-model="form.kind" size="small"  @change="pushType">
          <el-radio-button label="0">链接</el-radio-button>
          <el-radio-button label="1">专题</el-radio-button>
          <el-radio-button label="2">活动</el-radio-button>
          <el-radio-button label="3">商品</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <div v-if="link">
        <el-form-item style="margin-bottom: 20px" label="链接" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
      </div>
      <div v-if="special">
        <el-form-item style="margin-bottom: 20px" label="选择专题" prop="name">
          <el-select
              v-model="form.name"
              multiple
              placeholder="请选择"
              style="width: 100%;"
          >
            <el-option
                v-for="item in apiName"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <div v-if="activity">
        <el-form-item style="margin-bottom: 20px" label="选择活动" prop="name">
          <el-select
              v-model="form.name"
              multiple
              placeholder="请选择"
              style="width: 100%;"
          >
            <el-option
                v-for="item in apiName"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <div v-if="commodity">
        <el-form-item style="margin-bottom: 20px" label="选择商品" prop="name">
          <el-select
              v-model="form.name"
              multiple
              placeholder="请选择"
              style="width: 100%;"
          >
            <el-option
                v-for="item in apiName"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item style="margin-bottom: 20px" label="标题" prop="title">
        <el-input v-model="form.title" maxlength="14" show-word-limit />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="内容" prop="content">
        <el-input
            type="textarea"
            :autosize="{ minRows: 5}"
            placeholder="请输入内容"
            v-model="form.content"
            maxlength="20"
            show-word-limit>
        </el-input>
        <div>
          APP推送标题不能超过<span class="color">14</span>个字。
          内容不能超过<span class="color">20</span>个字
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      link: true,
      special: false,
      activity: false,
      commodity: false,
      coustate: true,
      apiName: [],
      form: {
        kind: '',
        name: '',
        title: '',
        content: '',
      },
      rules: {
        kind: [
          { required: true, trigger: 'change', message: '请选择推送类型' },
        ],
        name: [
          { required: true, trigger: 'change', message: '不能为空' },
        ],
        title: [
          { required: true, trigger: 'blur', message: '请输入标题' },
        ],
        content: [
          { required: true, trigger: 'blur', message: '请输入短信内容' },
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
    this.requestName()
  },
  methods: {
    // 推送类型
    pushType(selectValue) {
      if (selectValue == 0) {
        this.link = true
        this.special = false
        this.activity = false
        this.commodity = false
      } else if (selectValue == 1) {
        this.link = false
        this.special = true
        this.activity = false
        this.commodity = false
      } else if (selectValue == 2) {
        this.link = false
        this.special = false
        this.activity = true
        this.commodity = false
      } else {
        this.link = false
        this.special = false
        this.activity = false
        this.commodity = true
      }
    },
    // 下拉
    async requestName() {
      const { data } = await matchBranchList()
      this.apiName = data
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = 'APP推送'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      console.log(event)
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const { code, message } = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.color {
  color: #ff4d51;
  font-weight: 700;
}
</style>
