<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-checkbox-group
          v-model="label"
          size="small"
          @change="getRoleArr"
      >
        <el-checkbox
            v-for="role in tag_list"
            :key="role.id"
            :label="role.id"
        >
          {{ role.name }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AdminEdit',
  data() {
    return {
      coustate: true,
      label: [],
      tag_list: [
        {
          name: '标签1',
          id: '1',
        },
        {
          name: '标签2',
          id: '2',
        },
        {
          name: '标签3',
          id: '3',
        },
        {
          name: '标签4',
          id: '4',
        },
      ],
      form: {},
      rules: {
        label: [
          { type: 'array', required: true, message: '请至少选择一个标签', trigger: 'change' }
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {},
  methods: {
    getRoleArr(event) {
      console.log(event)
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '设置标签'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    selectionStatus(event) {
      console.log(event)
      if (event == 2) {
        this.coustate = true
      } else {
        this.coustate = false
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const { code, message } = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.color {
  color: #ff4d51;
  font-weight: 700;
}
</style>
