<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <i class="header-icon el-icon-search"></i> 搜索
        </template>
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-row>
            <el-col :span="2">最近消费：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.recent_consumption" size="small" style="width: 100%">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">1周内</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">2周内</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">1个月内</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">1个月前</el-radio></el-col>
                <el-col :span="2"><el-radio label="5">2个月前</el-radio></el-col>
                <el-col :span="2"><el-radio label="6">3个月前</el-radio></el-col>
                <el-col :span="2"><el-radio label="7">6个月前</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">消费次数：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.consumption_times" size="small" style="width: 100%;">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">1次+</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">2次+</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">3次+</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">4次+</el-radio></el-col>
                <el-col :span="2"><el-radio label="5">10次+</el-radio></el-col>
                <el-col :span="2"><el-radio label="6">20次+</el-radio></el-col>
                <el-col :span="2"><el-radio label="7">30次+</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">消费金额：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.consumption_amount" size="small" style="width: 100%;">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">0-50</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">51-100</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">101-150</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">151-200</el-radio></el-col>
                <el-col :span="2"><el-radio label="5">201-300</el-radio></el-col>
                <el-col :span="2"><el-radio label="6">301-500</el-radio></el-col>
                <el-col :span="2"><el-radio label="7">501-1000</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">订单均价：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.average_order_price" size="small" style="width: 100%;">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">0-20</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">21-50</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">51-100</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">101-150</el-radio></el-col>
                <el-col :span="2"><el-radio label="5">151-200</el-radio></el-col>
                <el-col :span="2"><el-radio label="6">201-300</el-radio></el-col>
                <el-col :span="2"><el-radio label="7">301-500</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">商品分类：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.cate_id" size="small" style="width: 100%;">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">童装</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">服装</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">配件</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">工具</el-radio></el-col>
                <el-col :span="2"><el-radio label="5">杂货</el-radio></el-col>
                <el-col :span="2"><el-radio label="6">零食</el-radio></el-col>
                <el-col :span="2"><el-radio label="7">洗护</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">会员等级：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.u_l_id" size="small" style="width: 100%;">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">普通会员</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">黄金会员</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">白金会员</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">钻石会员</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="2">用户标签：</el-col>
            <el-col :span="22">
              <el-radio-group v-model="queryForm.user_label" size="small" style="width: 100%;">
                <el-col :span="2"><el-radio label="">不限</el-radio></el-col>
                <el-col :span="2"><el-radio label="1">标签</el-radio></el-col>
                <el-col :span="2"><el-radio label="2">标签</el-radio></el-col>
                <el-col :span="2"><el-radio label="3">标签</el-radio></el-col>
                <el-col :span="2"><el-radio label="4">标签</el-radio></el-col>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" plain size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel  :span="24">
      <el-button class="button" type="primary" @click="massTexting" size="small">群发短信</el-button>
      <el-button class="button" type="primary" @click="massMessaging" size="small">群发站内信</el-button>
      <el-button class="button" type="primary" @click="appPush" size="small">APP推送</el-button>
      <el-button class="button" type="primary" @click="setLabel" size="small">设置标签</el-button>
      <el-button class="button" type="primary" @click="handleEdit" size="small">赠送优惠券</el-button>
      <el-button class="button" type="primary" @click="handleEdit" size="small">导出数据</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="tableData"
        @selection-change="setSelectRows"
        style="width: 100%">
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column
          align="center"
          prop="id"
          label="用户ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="bank_no"
          label="用户账号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="u_l_id"
          label="会员等级">
      </el-table-column>
      <el-table-column
          align="center"
          prop="consumption_money"
          label="消费金额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="consumption_count"
          label="消费次数">
      </el-table-column>
      <el-table-column
          align="center"
          prop="average_price"
          label="订单均价">
      </el-table-column>
      <el-table-column
          align="center"
          label="最后购买时间"
          prop="final_purchase_time"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.final_purchase_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <massTexting_Edit ref="massTexting_Edit" />
    <massMessaging_Edit ref="massMessaging_Edit" />
    <appPush_Edit ref="appPush_Edit" />
    <setLabel_Edit ref="setLabel_Edit" />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import massTexting_Edit from './components/massTexting_Edit'
import massMessaging_Edit from './components/massMessaging_Edit'
import appPush_Edit from './components/appPush_Edit'
import setLabel_Edit from './components/setLabel_Edit'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {
    massTexting_Edit,
    massMessaging_Edit,
    appPush_Edit,
    setLabel_Edit
  },
  props: [],
  data() {
    return {
      activeName: '1',
      selectRows: '',
      queryForm: {
        page: 1,
        pageSize: 10,
        recent_consumption: '',
        consumption_times: '',
        consumption_amount: '',
        average_order_price: '',
        cate_id: '',
        u_l_id: '',
        user_label: '',
      },
      tableData: [
        {
          id: '1',
          name: '益力多',
          user_id: '151sdfs',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          user_id: '151sdfs',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          user_id: '151sdfs',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // 群发短信
    massTexting(row) {
      if (row.id) {
        this.$refs['massTexting_Edit'].showEdit({ id: row.id })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          const user_id = this.selectRows.map((item) => item.user_id).join()
          this.$refs['massTexting_Edit'].showEdit({ id, user_id })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    // 群发站内信
    massMessaging(row) {
      if (row.id) {
        this.$refs['massMessaging_Edit'].showEdit({ id: row.id })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          const user_id = this.selectRows.map((item) => item.user_id).join()
          this.$refs['massMessaging_Edit'].showEdit({ id, user_id })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    // APP推送
    appPush(row) {
      if (row.id) {
        this.$refs['appPush_Edit'].showEdit({ id: row.id })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          const kind = 0
          this.$refs['appPush_Edit'].showEdit({ id, kind })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    // 设置标签
    setLabel(row) {
      if (row.id) {
        this.$refs['setLabel_Edit'].showEdit({ id: row.id })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          const kind = 0
          this.$refs['setLabel_Edit'].showEdit({ id, kind })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}

</script>
<style>
  .button {
    margin-top: 20px;
  }

  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
